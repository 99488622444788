import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"

export default function IncompletePage() {
  return (
    <>
      <SEO title="Payment Incomplete" />
      <Container className="d-flex-column justify-content-center align-items-center mt-4">
        <Card className="w-100" style={{ maxWidth: `50rem`, margin: `0 auto` }}>
          <Card.Header
            className="m-3 p-3 text-center"
            style={{ textShadow: `0.25rem 0.25rem #412456` }}
          >
            <h3>Payment Not Completed</h3>
          </Card.Header>
          <Card.Body className="m-3">
            <p>The checkout process was not completed in full.</p>
            <br />
            <p>If you elected not to continue, welcome back.</p>
            <br />
            <p>
              If you were attempting to finalise the transaction, something has
              gone awry with your payment method.
            </p>
            <br />
            <p>
              Your account has not been charged at this point. You can try
              another payment method, or contact your bank for more information.
            </p>
          </Card.Body>

          <Card.Footer className="m-0 p-0">
            <Button className="w-100">
              <Link to="/readings">Continue Browsing</Link>
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </>
  )
}
